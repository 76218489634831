<template>
    <div>
        <!--details packages-->

        <b-card>
            <validationObserver ref="courseQustions">
                <b-row>
                    <b-col lg="4" md="4">
                        <ek-input-select
                            name="الدورة أو بنك "
                            label="الدورة أو بنك"
                            ref="order"
                            :options="prevExamsName"
                            v-model="courseQDto.previousExamId"
                            placeholder=" الدورة أو بنك "
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'اضافة الدورة أو بنك  مطلوب',
                                },
                            ]"
                        ></ek-input-select>
                    </b-col>
                    <b-col lg="4" md="4">
                        <ek-input-text
                            name="ترتيب السؤال*  "
                            label="ترتيب السؤال *"
                            ref="order"
                            v-model="courseQDto.order"
                            placeholder=" ترتيب السؤال* "
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'اضافة ترتيب السؤال  مطلوب',
                                },
                            ]"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="4">
                        <ek-input-text
                            name="رقم المجموعة "
                            label="رقم المجموعة"
                            ref="order"
                            type="number"
                            v-model.number="courseQDto.group"
                            placeholder=" رقم المجموعة "
                        ></ek-input-text>
                    </b-col>
                </b-row>
            </validationObserver>
        </b-card>
        <b-card>
            <template #header>
                <div
                    class="w-100 d-flex justify-content-between align-content-center"
                >
                    <h4 class="m-0">إضافة سؤال</h4>
                </div>
            </template>
            <div class="correct-border w-100 p-2 mb-2 rounded">
                <div
                    class="w-100 d-flex justify-content-between align-content-center"
                >
                    <h4 class="m-0">نص السؤال</h4>

                    <div class="d-flex mx-2">
                        <!-- <b-button
                            :variant="'null'"
                            class="p-0 b-icon"
                            style="margin-left: 4px; margin-right: 4px"
                            v-b-tooltip.hover
                            :title="'delete'"
                            @click="deletedMedia(courseQDto.answers, i)"
                        >
                            <unicon :fill="'red'" height="18px" name="trash">
                            </unicon>
                        </b-button> -->
                        <b-button
                            v-for="type in types"
                            :key="type.id"
                            :variant="'primary'"
                            class="p-0 b-icon"
                            style="margin-left: 4px; margin-right: 4px"
                            v-b-tooltip.hover
                            :title="type.title"
                            @click="addQuestion(type)"
                        >
                            <unicon
                                :fill="'white'"
                                height="18px"
                                :name="type.icon"
                            >
                            </unicon>
                        </b-button>
                    </div>
                </div>
                <ek-input-text
                    ref="order"
                    v-model="courseQDto.text"
                    :name="'السؤال'"
                    style="width: 100%"
                    :placeholder="'السؤال'"
                    :rules="[
                        {
                            type: 'required',
                            message: 'اضافة السؤال  مطلوب',
                        },
                    ]"
                ></ek-input-text>
                <ek-input-image
                    v-if="!courseQDto.fileUrl && courseQDto.fileType"
                    :required="true"
                    name="image"
                    style="width: 100%; margin-top: 8px"
                    @imageDelete="deletedMedia(index)"
                    @input="UploadFile($event)"
                    :accept="getAcceptType(courseQDto.fileType)"
                >
                    <h5>ادرج الملف هنا او انقر للرفع</h5>
                    <unicon
                        fill="red"
                        :name="
                            courseQDto.fileType == 'Audio'
                                ? 'microphone'
                                : courseQDto.fileType == 'Photo'
                                ? 'image-plus'
                                : courseQDto.fileType == 'Video'
                                ? 'video'
                                : 'file-plus'
                        "
                        height="12px"
                        class="mx-1"
                    ></unicon>
                </ek-input-image>
                <div v-else class="image-picker-preview-wrapper">
                    <div class="preview multi rounded shadow-sm">
                        <img
                            v-if="courseQDto.fileType == 'Photo'"
                            style="
                                width: 100%;
                                height: 200px;
                                object-fit: cover;
                                border-radius: 10px;
                            "
                            :src="
                                $store.getters['app/domainHost'] +
                                '/' +
                                courseQDto.fileUrl
                            "
                            alt=""
                            srcset=""
                        /><a
                            :href="
                                $store.getters['app/domainHost'] +
                                '/' +
                                courseQDto.fileUrl
                            "
                            target="_blank"
                        >
                            <img
                                v-if="courseQDto.fileType == 'Pdf'"
                                style="
                                    width: 100%;
                                    height: 200px;
                                    object-fit: contain;
                                    border-radius: 10px;
                                "
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                                alt=""
                        /></a>
                        <video
                            v-if="courseQDto.fileType == 'Video'"
                            controls
                            autoplay
                            style="
                                width: 100%;
                                height: 200px;
                                object-fit: cover;
                                border-radius: 10px;
                            "
                        >
                            <source
                                :src="
                                    $store.getters['app/domainHost'] +
                                    '/' +
                                    courseQDto.fileUrl
                                "
                                type="video/mp4"
                            />
                        </video>
                        <audio
                            v-if="courseQDto.fileType == 'Audio'"
                            controls
                            style="
                                width: 100%;

                                object-fit: cover;
                                border-radius: 10px;
                            "
                        >
                            <source
                                :src="
                                    $store.getters['app/domainHost'] +
                                    '/' +
                                    courseQDto.fileUrl
                                "
                                type="audio/mp3"
                            />
                        </audio>

                        <div
                            class="delete-btn"
                            @click="courseQDto.fileUrl = null"
                        >
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                fill="#fff"
                                height="20px"
                                data-prefix="far"
                                data-icon="trash-alt"
                                class="svg-inline--fa fa-trash-alt fa-w-14"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path
                                    d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
                                ></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <validationObserver ref="courseQustions">
                <div
                    class="w-100 d-flex justify-content-start align-content-center"
                >
                    <h4 class="m-0">اضافة اجابة</h4>

                    <div class="d-flex mx-2">
                        <b-button
                            :variant="'primary'"
                            class="p-0"
                            style="margin-left: 4px; margin-right: 4px"
                            @click="addMedias"
                        >
                            <unicon :fill="'white'" height="18px" name="plus">
                            </unicon>
                        </b-button>
                    </div>
                </div>
                <div class="correct-border w-100 p-2 mb-2 rounded">
                    <div class="position-relative w-100 h-50 mt-2">
                        <div v-for="(media, i) in courseQDto.answers" :key="i">
                            <div class="mt-3">
                                <div>
                                    <h6>الإجابة رقم ({{ i + 1 }})</h6>
                                </div>
                                <div
                                    class="w-100 mt-2 d-flex justify-content-between align-content-center"
                                >
                                <!-- {{ media.isCorrect }} -->
                                    <b-form-radio-group
                                        v-model="media.isCorrect"
                                        :options="options"
                                        value-field="item"
                                        text-field="name"
                                    ></b-form-radio-group>
                                    <div class="d-flex mx-2">
                                        <b-button
                                            :variant="'null'"
                                            class="p-0 b-icon"
                                            style="
                                                margin-left: 4px;
                                                margin-right: 4px;
                                            "
                                            v-b-tooltip.hover
                                            :title="'حذف الإجابة'"
                                            @click="
                                                deleteAnswers(
                                                    courseQDto.answers,
                                                    i
                                                )
                                            "
                                        >
                                            <unicon
                                                :fill="'red'"
                                                height="18px"
                                                name="trash"
                                            >
                                            </unicon>
                                        </b-button>
                                        <b-button
                                            v-for="type in types"
                                            :key="type.id"
                                            :variant="'primary'"
                                            class="p-0 b-icon"
                                            style="
                                                margin-left: 4px;
                                                margin-right: 4px;
                                            "
                                            v-b-tooltip.hover
                                            :title="type.title"
                                            @click="
                                                courseQDto.answers[i].fileType =
                                                    type.title || 'Pdf'
                                            "
                                        >
                                            <unicon
                                                :fill="'white'"
                                                height="18px"
                                                :name="type.icon"
                                            >
                                            </unicon>
                                        </b-button>
                                    </div>
                                </div>
                                <div
                                    class="-mt-2 flex-column align-content-center justify-content-center w-100"
                                >
                                    <div
                                        class="flex flex-col justify-content-between align-content-center w-100"
                                    >
                                        <ek-input-text
                                            ref="order"
                                            v-model="media.text"
                                            :name="'جواب' + (i + 1)"
                                            style="width: 100%"
                                            :placeholder="'جواب' + (i + 1)"
                                            :rules="[
                                                {
                                                    type: 'required',
                                                    message:
                                                        'اضافة جواب  مطلوب',
                                                },
                                            ]"
                                        ></ek-input-text>
                                        <ek-input-image
                                            v-if="
                                                !courseQDto.answers[i]
                                                    .fileUrl &&
                                                courseQDto.answers[i].fileType
                                            "
                                            :required="true"
                                            name="image"
                                            style="
                                                width: 100%;
                                                margin-top: -10px;
                                            "
                                            @input="storeAnswersFile($event, i)"
                                            @imageDelete="deletedMedia(index)"
                                            :accept="
                                                getAcceptType(
                                                    courseQDto.answers[i]
                                                        .fileType
                                                )
                                            "
                                        >
                                            <h5>
                                                ادرج الملف هنا او انقر للرفع
                                            </h5>
                                            <unicon
                                                fill="primary"
                                                :name="
                                                    media.fileType == 'Audio'
                                                        ? 'microphone'
                                                        : media.fileType ==
                                                          'Photo'
                                                        ? 'image-plus'
                                                        : media.fileType ==
                                                          'Video'
                                                        ? 'video'
                                                        : 'file-plus'
                                                "
                                                height="12px"
                                                class="mx-1"
                                            ></unicon>
                                        </ek-input-image>
                                        <div
                                            v-else
                                            class="image-picker-preview-wrapper"
                                        >
                                            <div
                                                class="preview multi rounded shadow-sm"
                                            >
                                                <img
                                                    v-if="
                                                        courseQDto.answers[i]
                                                            .fileType == 'Photo'
                                                    "
                                                    style="
                                                        width: 100%;
                                                        height: 200px;
                                                        object-fit: cover;
                                                        border-radius: 10px;
                                                    "
                                                    :src="
                                                        $store.getters[
                                                            'app/domainHost'
                                                        ] +
                                                        '/' +
                                                        courseQDto.answers[i]
                                                            .fileUrl
                                                    "
                                                    alt=""
                                                    srcset=""
                                                /><a
                                                    :href="
                                                        $store.getters[
                                                            'app/domainHost'
                                                        ] +
                                                        '/' +
                                                        courseQDto.answers[i]
                                                            .fileUrl
                                                    "
                                                    target="_blank"
                                                >
                                                    <img
                                                        v-if="
                                                            courseQDto.answers[
                                                                i
                                                            ].fileType == 'Pdf'
                                                        "
                                                        style="
                                                            width: 100%;
                                                            height: 200px;
                                                            object-fit: contain;
                                                            border-radius: 10px;
                                                        "
                                                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                                                        alt=""
                                                /></a>
                                                <video
                                                    v-if="
                                                        courseQDto.answers[i]
                                                            .fileType == 'Video'
                                                    "
                                                    controls
                                                    autoplay
                                                    style="
                                                        width: 100%;
                                                        height: 200px;
                                                        object-fit: cover;
                                                        border-radius: 10px;
                                                    "
                                                >
                                                    <source
                                                        :src="
                                                            $store.getters[
                                                                'app/domainHost'
                                                            ] +
                                                            '/' +
                                                            courseQDto.answers[
                                                                i
                                                            ].fileUrl
                                                        "
                                                        type="video/mp4"
                                                    />
                                                </video>
                                                <audio
                                                    v-if="
                                                        courseQDto.answers[i]
                                                            .fileType == 'Audio'
                                                    "
                                                    style="
                                                        width: 100%;

                                                        object-fit: cover;
                                                        border-radius: 10px;
                                                    "
                                                    controls
                                                >
                                                    <source
                                                        :src="
                                                            $store.getters[
                                                                'app/domainHost'
                                                            ] +
                                                            '/' +
                                                            courseQDto.answers[
                                                                i
                                                            ].fileUrl
                                                        "
                                                        type="audio/mp3"
                                                    />
                                                </audio>

                                                <div
                                                    class="delete-btn"
                                                    @click="
                                                        courseQDto.answers[
                                                            i
                                                        ].fileUrl = null
                                                    "
                                                >
                                                    <svg
                                                        aria-hidden="true"
                                                        focusable="false"
                                                        fill="#fff"
                                                        height="20px"
                                                        data-prefix="far"
                                                        data-icon="trash-alt"
                                                        class="svg-inline--fa fa-trash-alt fa-w-14"
                                                        role="img"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 448 512"
                                                    >
                                                        <path
                                                            d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="w-100 d-flex justify-content-start align-content-center"
                >
                    <h4 class="m-0">إضافة تلميح</h4>
                    <div class="d-flex mx-1">
                        <b-button
                            :variant="'primary'"
                            class="p-0"
                            style="margin-left: 4px; margin-right: 4px"
                            @click="addnewHint"
                            v-if="courseQDto.hint === null"
                        >
                            <unicon :fill="'white'" height="18px" name="plus">
                            </unicon>
                        </b-button>
                    </div>
                </div>

                <div
                    v-if="courseQDto.hint !== null"
                    class="correct-border w-100 p-2 mb-2 rounded"
                >
                    <div
                        class="w-100 d-flex justify-content-between align-content-center"
                    >
                        <h4 class="m-0">نص التلميح</h4>

                        <div class="d-flex mx-2">
                            <b-button
                                :variant="'null'"
                                class="p-0 b-icon"
                                style="margin-left: 4px; margin-right: 4px"
                                v-b-tooltip.hover
                                :title="'delete'"
                                @click="deleteHint"
                            >
                                <unicon
                                    :fill="'red'"
                                    height="18px"
                                    name="trash"
                                >
                                </unicon>
                            </b-button>
                            <b-button
                                v-for="type in types"
                                :key="type.id"
                                :variant="'primary'"
                                class="p-0 b-icon"
                                style="margin-left: 4px; margin-right: 4px"
                                v-b-tooltip.hover
                                :title="type.title"
                                @click="addHint(type)"
                            >
                                <unicon
                                    :fill="'white'"
                                    height="18px"
                                    :name="type.icon"
                                >
                                </unicon>
                            </b-button>
                        </div>
                    </div>

                    <ek-input-text
                        ref="order"
                        v-model="courseQDto.hint.text"
                        :name="'التلميح'"
                        style="width: 100%"
                        :placeholder="'التلميح'"
                        :rules="[
                            {
                                type: 'required',
                                message: 'اضافة التلميح  مطلوب',
                            },
                        ]"
                    ></ek-input-text>
                    <ek-input-image
                        v-if="
                            !courseQDto.hint.fileUrl && courseQDto.hint.fileType
                        "
                        :required="true"
                        name="image"
                        style="width: 100%; margin-top: 8px"
                        @imageDelete="deletedMedia(index)"
                        @input="storeFile($event)"
                        :accept="getAcceptType(courseQDto.hint.fileType)"
                    >
                        <h5>ادرج الملف هنا او انقر للرفع</h5>
                        <unicon
                            fill="red"
                            :name="
                                courseQDto.hint.fileType == 'Audio'
                                    ? 'microphone'
                                    : courseQDto.hint.fileType == 'Photo'
                                    ? 'image-plus'
                                    : 'video'
                            "
                            height="12px"
                            class="mx-1"
                        ></unicon>
                    </ek-input-image>
                    <div v-else class="image-picker-preview-wrapper">
                        <div class="preview multi rounded shadow-sm">
                            <img
                                v-if="courseQDto.hint.fileType == 'Photo'"
                                style="
                                    width: 100%;
                                    height: 200px;
                                    object-fit: cover;
                                    border-radius: 10px;
                                "
                                :src="
                                    $store.getters['app/domainHost'] +
                                    '/' +
                                    courseQDto.hint.fileUrl
                                "
                                alt=""
                                srcset=""
                            /><a
                                :href="
                                    $store.getters['app/domainHost'] +
                                    '/' +
                                    courseQDto.hint.fileUrl
                                "
                                target="_blank"
                            >
                                <img
                                    v-if="courseQDto.hint.fileType == 'Pdf'"
                                    style="
                                        width: 100%;
                                        height: 200px;
                                        object-fit: contain;
                                        border-radius: 10px;
                                    "
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                                    alt=""
                            /></a>
                            <video
                                v-if="courseQDto.hint.fileType == 'Video'"
                                controls
                                autoplay
                                style="
                                    width: 100%;
                                    height: 200px;
                                    object-fit: cover;
                                    border-radius: 10px;
                                "
                            >
                                <source
                                    :src="
                                        $store.getters['app/domainHost'] +
                                        '/' +
                                        courseQDto.hint.fileUrl
                                    "
                                    type="video/mp4"
                                />
                            </video>
                            <audio
                                v-if="courseQDto.hint.fileType == 'Audio'"
                                controls
                                style="
                                    width: 100%;

                                    object-fit: cover;
                                    border-radius: 10px;
                                "
                            >
                                <source
                                    :src="
                                        $store.getters['app/domainHost'] +
                                        '/' +
                                        courseQDto.hint.fileUrl
                                    "
                                    type="audio/mp3"
                                />
                            </audio>

                            <div
                                class="delete-btn"
                                @click="courseQDto.hint.fileUrl = null"
                            >
                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    fill="#fff"
                                    height="20px"
                                    data-prefix="far"
                                    data-icon="trash-alt"
                                    class="svg-inline--fa fa-trash-alt fa-w-14"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                >
                                    <path
                                        d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <b-row class="mt-4">
                    <b-col cols="12" lg="8" md="8">
                        <div
                            class="d-flex mx-1 justify-content-start align-content-center align-items-center"
                        >
                            <h4 class="mt-2">وسوم السؤال</h4>
                        </div>
                    </b-col>
                    <b-col cols="12" lg="4" md="4">
                        <div
                            class="d-flex mx-1 justify-content-center align-content-center align-items-center"
                        >
                            <ek-input-select
                                name="اختر وسم "
                                label="اختر وسم"
                                :options="tagsList.tags"
                                v-model="tags"
                                ref="order"
                                placeholder=" اختر وسم "
                                class="w-100"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'اضافة اختر وسم  مطلوب',
                                    },
                                ]"
                            ></ek-input-select>
                            <b-button
                                v-if="!isTagAdded(tags) && tags"
                                :variant="'primary'"
                                class="p-0"
                                style="
                                    margin-top: 20px;
                                    margin-left: 4px;
                                    margin-right: 4px;
                                    height: 40px;
                                    width: 40px;
                                "
                                @click="addNewTags(tags)"
                            >
                                <unicon
                                    :fill="'white'"
                                    height="18px"
                                    name="plus"
                                >
                                </unicon>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <div
                    class="d-flex mx-1 justify-content-start flex-wrap align-content-center align-items-center"
                >
                    <v-chip
                        v-for="item in courseQDto.tags"
                        :key="item.id"
                        class="chip mt-2"
                        :style="`outline: auto;outline-color:${item.color}`"
                     > 
                        <div
                            class="d-flex w-100 mx-1 justify-content-between align-content-center align-items-center"
                        >
                            <div
                                @click="deleteNewTags(courseQDto.tags, item.id)"
                                class="chip-close"
                            >
                                <svg
                                    class="chip-svg"
                                     style="color: red;"
                                    focusable="false"
                                    viewBox="0 0 24 24"
                                    aria-hidden="true"
                                >
                                    <path
                                        d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
                                    ></path>
                                </svg>
                            </div>
                            <div                         
 class="mx-2 text-black">
                                {{ item.name }}
                            </div>
                        </div>
                    </v-chip>
                </div>
            </validationObserver>
        </b-card>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { fileWindowApi } from "@core/utils/uploader";

export default {
    props: {
        id: String,
    },
    data() {
        return {
            panels: [
                {
                    title: "Title 1",
                    text: "Lorem ipsum dolor sit amet.",
                    expanded: false,
                },
                {
                    title: "Title 2",
                    text: "Consectetur adipisicing elit.",
                    expanded: false,
                },
                {
                    title: "Title 3",
                    text: "Sed do eiusmod tempor incididunt.",
                    expanded: false,
                },
            ],
            types: [
                {
                    id: 1,
                    icon: "video",
                    title: "Video",
                },
                {
                    id: 2,

                    icon: "microphone",
                    title: "Audio",
                },
                {
                    id: 3,
                    icon: "image-plus",
                    title: "Photo",
                },
                {
                    id: 4,
                    icon: "file-plus",
                    title: "Pdf",
                },
            ],

            options: [
                { item: true, name: "اجابة صحيحة" },
                { item: false, name: "اجابة خاطئة" },
            ],
            tags: "",
        };
    },
    computed: {
        ...mapState({
            prevExamsName: (state) => state.courseQuestion.prevExamsName,
            tagsList: (state) => state.settings.tagsList,
            courseQDto: (state) => state.courseQuestion.courseQDto,
        }),

        ...mapGetters(["CourseQuestionsList", "getUrl"]),
    },
    methods: {
        getAcceptType(fileType) {
            if (fileType === "Audio") {
                return "audio/mp3";
            } else if (fileType === "Photo") {
                return "image/png, image/jpeg";
            } else if (fileType === "Video") {
                return "video/mp4";
            } else {
                return "application/pdf";
            }
        },
        addQuestion(type) {
            console.log(type.title);
            if (type.title) {
                this.courseQDto.fileType = type.title;
                 this.courseQDto.fileUrl = null;
            } else {
                this.courseQDto.fileType = "Pdf";
                 this.courseQDto.fileUrl = "";
            }
        },
        addHint(type) {
            console.log(type.title);
            if (type.title) {
                this.courseQDto.hint.fileType = type.title;
                // this.courseQDto.hint.text = "";
                this.courseQDto.hint.fileUrl = null;
            } else {
                this.courseQDto.hint.fileType = "Pdf";
                // this.courseQDto.hint.text = null;
                this.courseQDto.hint.fileUrl = "";
            }
        },
        addNewTags(id) {
            const tagIndex = this.courseQDto.tags.findIndex(
                (el) => el.id === id
            );

            if (tagIndex === -1) {
                const tagToAdd = this.tagsList.tags.find((el) => el.id === id);
                if (tagToAdd) {
                    this.courseQDto.tags.push(tagToAdd);
                }
            } else {
                this.courseQDto.tags.splice(tagIndex, 1);
            }
        },

        deleteNewTags(item, id) {
          
            const s = item.findIndex((ele) => ele.id === id);

            item = item.splice(s, 1);
        },
        itemStyle(color) {
            console.log(color);
      const textColor = this.isColorLight(color) ? '#000000' : '#ffffff';
      return {
        backgroundColor: color,
        color: textColor,
      };
    },
    isColorLight(color) {
      const hex = color.replace('#', '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      return brightness > 155;
    },
        isTagAdded(id) {
            if (!this.courseQDto.tags) {
                return false;
            }
            for (let i = 0; i < this.courseQDto.tags.length; i++) {
                if (this.courseQDto.tags[i].id === id) {
                    return true;
                }
            }
            return false;
        },
        addMedias() {
            console.log(this.courseQDto, "tttttttttt");
            this.courseQDto.answers.push({
                fileUrl: null,
                fileType: null,
                isCorrect: false,
            });
        },
        addnewHint() {
            console.log(this.courseQDto, "tttttttttt");
            this.courseQDto.hint = {
                text: null,
                fileUrl: null,
                fileType: null,
            };
        },
        deleteHint() {
            this.courseQDto.hint = null;
        },
        deleteAnswers(item, id) {
            const s = item.findIndex((ele, index) => index === id);

            item = item.splice(s, 1);
        },
        deleteTags(item, id) {
            const s = item.findIndex((ele, index) => index === id);

            item = item.splice(s, 1);
        },
        addTags(target) {
            target.push("");
        },

        ...mapActions([
            "getCourseQuestionsDetails",
            "addFileUrl",
            "addFileAnser",
            "addFileHint",
            "getPrevExamNames",
            "getTags",
        ]),
        fileWindowApi,
        editCourseQuestionsList() {
            this.$store.commit("IS_DIALOG_OPEN", true);
        },
        goToDetails(row) {
            this.$router.push(`/admin/courseQuestion/${row.id}`);
        },
        UploadFile(event) {
            if (event && event.file) {
                this.addFileUrl({
                    ...event,
                    folderName: "CourseQuestion",
                }).then((res) => {
                    console.log(res, "fdsfs");

                    this.courseQDto.fileUrl = res.data;
                });
            }
        },
        storeFile(event) {
            if (event && event.file) {
                this.addFileHint({
                    ...event,
                    folderName: "CourseQuestion",
                }).then((res) => {
                    console.log(res, "fdsfs");
                    this.courseQDto.hint.fileUrl = res.data;
                });
            }
        },

        storeAnswersFile(event, i) {
            if (event && event.file) {
                this.$store
                    .dispatch("addFileAnser", {
                        payload: { ...event, folderName: "CourseQuestion" },
                        index: i,
                    })
                    .then((res) => {
                        console.log(res.data, "fdsfs");
                        this.courseQDto.answers[i].fileUrl = res.data;
                    })
                    .catch((error) => {
                        console.error("Error uploading file:", error);
                    });
            }
        },
    },
    created() {
        if (this.id !== "0") {
            this.getCourseQuestionsDetails(this.id).then(() => {
                this.courseQDto.previousExamId = this.id;
            });
        }
        this.getPrevExamNames();
        this.getTags();
    },
};
</script>
<style lang="scss" scoped>
.lable {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
}
.expansion-panels {
    width: 100%;
}

.panel {
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.panel-header {
    padding: 10px;
    background-color: #f0f0f0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.panel-content {
    padding: 10px;
    display: block;
}
.chip {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  
    cursor: default;
    height: 46px;
    min-width: 100px;
    margin: 0 4px 0 4px;
    
    padding: 4px;
    font-size: 14px;
    font-color: #fff;
    align-items: center;
    border-radius: 16px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: start;
}

.chip-svg {
    color: #ffffff;
    cursor: pointer;
    height: auto;
    margin: 4px;
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
}
.chip-svg:hover {
    color: #666666;
}
.image-picker-preview-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    .preview.multi {
        flex: 0 0 50%;
        margin: 0.4rem 0;
        padding: 0 0.3rem;

        img {
            object-fit: cover;
            height: 100%;
        }

        .delete-btn {
            right: 0.3rem;
        }
    }
}
.preview {
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem !important;
    transition: 0.3s;
    width: 100%;
    &-item-name {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20%;
        width: auto;
    }

    .delete-btn {
        right: 0;
        cursor: pointer;
        border: none;
        padding: 0.4rem 0.8rem;
        background-color: rgb(206, 35, 35);
        color: #fff;
        box-shadow: -4px 5px 15px -5px #000 !important;
        position: absolute;
        z-index: 10;
        top: 0px;
        border-radius: 0 0 0 5px;
    }

    img {
        transition: 1s;
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: 0.5rem;
        user-select: none;
    }
    video {
        width: 100%;
    }
}
</style>
